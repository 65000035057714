<template>
  <div>
    <report-list-add-new :is-add-new-report-sidebar-active.sync="isAddNewReportSidebarActive"
      @refetch-data="refetchData" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>عرض</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>مدخلات</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="بحث..." />
              <b-button variant="primary" :to="{ name: 'reports-add' }">
                <span class="text-nowrap">إضافة {{ $t("report") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refReportListTable" class="table-responsive" :items="fetchReports" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="لم يتم العثور على سجلات مطابقة"
        :sort-desc.sync="isSortDirDesc">
        <!-- Column: type text -->
        <template #cell(name)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type text -->
        <template #cell(description)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: type icon -->

        <!-- Column: Status -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item :to="{ name: 'reports-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">عرض من {{ dataMeta.from }} إلى {{ dataMeta.to }} من {{ dataMeta.of }} مدخلات</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalReport" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ReportListFilters from './ReportListFilters.vue';
import useReportList from './useReportList';
import reportStore from '../reportStore';
import ReportListAddNew from './ReportListAddNew.vue';

export default {
  components: {
    ReportListFilters,
    ReportListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    deleteReport(report) {
      store.dispatch('report/deleteReport', { id: report.id })
        .then((response) => {
          this.$emit('refetch-data');
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            reportData.value = undefined;
          }
        });
    },
    confirmDelete(report) {
      this.$bvModal
        .msgBoxConfirm(`يرجى تأكيد أنك تريد حذف العنصر  ${report.id}.`, {
          title: 'يرجى التأكيد',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'نعم',
          cancelTitle: 'لا',
          cancelVariant: 'outline-warning',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteReport(report);
          }
        });
    },

  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report';

    // Register report
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME);
    });

    const isAddNewReportSidebarActive = ref(false);

    const {
      fetchReports,
      tableColumns,
      perPage,
      currentPage,
      totalReport,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReportListTable,
      refetchData,

      // UI
    } = useReportList();
    return {
      // Sidebar
      isAddNewReportSidebarActive,
      fetchReports,
      tableColumns,
      perPage,
      currentPage,
      totalReport,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReportListTable,
      refetchData,
      // Filter
      avatarText,
      // UI
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
