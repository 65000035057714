<template>
  <b-sidebar
    id="add-new-report-sidebar"
    :visible="isAddNewReportSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-report-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          إضافة {{ $t('report') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- name -->

          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="اسم"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="reportData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end name -->
          <!-- description -->

          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              label="وصف"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="reportData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end description -->
          <!-- style_id -->
          <validation-provider
            #default="validationContext"
            name="style_id"
            rules="required"
          >
            <b-form-group
              label="النمط المعتمد"
              label-for="style_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="reportData.style_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="styles"
                :reduce="val => val.value"
                :clearable="false"
                input-id="style_id"
                @input="styleChanged"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end style_id -->
          <!-- header_id -->
          <validation-provider
            #default="validationContext"
            name="header_id"
            rules="required"
          >
            <b-form-group
              label="رأس الصفحة"
              label-for="header_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="reportData.header_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="headers"
                :reduce="val => val.value"
                :clearable="false"
                input-id="header_id"
                @input="headerChanged"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- end header_id -->

          <!-- footer_id -->
          <validation-provider
            #default="validationContext"
            name="footer_id"
            rules="required"
          >
            <b-form-group
              label="ذيل  الصفحة"
              label-for="footer_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="reportData.footer_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="footers"
                :reduce="val => val.value"
                :clearable="false"
                input-id="footer_id"
                @input="footerChanged"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- footer_id -->

          <!-- ---------------------------- pages --------------------- -->
          <b-row>
            <b-col
              cols="12"
              md="12"
              lg="12"
            >
              <div class="d-flex">
                <feather-icon
                  icon="FileText"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  الصفحات
                  <!-- Add item to pages -->
                  <b-button
                    class="btn-icon rounded-circle btn-sm"
                    variant="primary"
                    style="width: 14px; height: 14px; padding: 0"
                    size="sm"
                    @click="addPage()"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="10"
                    />
                  </b-button>
                </h4>
              </div>
            </b-col>
            <b-col cols="12">
              <hr class="my-2">
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="(item, i) in reportData.pages"
              :id="item.id"
              :key="i"
              ref="row"
              cols="12"
              md="12"
              lg="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  lg="12"
                  style="margin: auto"
                >
                  <span class="text-overline"> الصفحة {{ i + 1 }} </span>
                  <b-button
                    v-if="i > 0"
                    class="btn-icon"
                    variant="danger"
                    size="sm"
                    style="left: 20px; width: 18px; height: 18px; padding: 0"
                    @click="removePage(i)"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="12"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  :key="`name-${i}`"
                  sm="8"
                  md="6"
                  lg="12"
                >
                  <!-- Field: label -->
                  <validation-provider
                    #default="validationContext"
                    :vid="`name-${i}`"
                    :name="`name-${i}`"
                  >
                    <b-form-group
                      label="اسم الصفحة"
                      :label-for="`name-${i}`"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-input
                        :id="`name-${i}`"
                        v-model="reportData.pages[i].name"
                        :value="item.name"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  :key="`description-${i}`"
                  sm="8"
                  md="6"
                  lg="12"
                >
                  <!-- Field: label -->
                  <validation-provider
                    #default="validationContext"
                    :vid="`description-${i}`"
                    :name="`description-${i}`"
                  >
                    <b-form-group
                      label="الوصف"
                      :label-for="`description-${i}`"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-input
                        :id="`description-${i}`"
                        v-model="reportData.pages[i].description"
                        :value="item.description"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  sm="8"
                  md="6"
                  lg="12"
                >
                  <!-- Field: layout_id -->

                  <validation-provider
                    #default="validationContext"
                    :vid="`layout_id${i}`"
                    :name="`layout_id${i}`"
                    rules="required"
                  >
                    <b-form-group
                      label="قالب الصفحة"
                      :label-for="`layout_id${i}`"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="reportData.pages[i].layout_id"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :options="layouts"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        :input-id="`layout_id${i}`"
                        @input="$event => layoutChanged($event, i)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12">
                  <hr class="my-2">
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- ---------------------------- pages --------------------- -->

          <!-- end footer_id -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BButton, BRow, BCol,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewReportSidebarActive',
    event: 'update:is-add-new-report-sidebar-active',
  },
  props: {
    isAddNewReportSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    addPage() {
      this.reportData.pages.push({

      });
    },
    removePage(index) {
      this.reportData.pages.splice(index, 1);
    },
    styleChanged(style) {
      this.reportData.style_id = style;
    },
    headerChanged(header) {
      this.reportData.header_id = header;
    },
    footerChanged(footer) {
      this.reportData.footer_id = footer;
    },
    layoutChanged(layout, i) {
      this.reportData.pages[i].layout_id = layout;
    },

    onSubmit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          store.dispatch('report/addReport', this.reportData)
            .then((response) => {
              this.$toast.success(response.data.message);
              this.$emit('refetch-data');
              this.$emit('update:is-add-new-report-sidebar-active', false);
            });
        }
      }).catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            this.$toast.error(err.response.data.message);
            this.$refs.refFormObserver.setErrors(err.response.data.errors);
          } else {
            this.$toast.error(err.response.data.error);
          }
        }
        console.log(err);
      });
    },

  },
  setup(props, { emit }) {
    const blankReportData = {
      name: '',
      description: '',
      style_id: '',
      header_id: '',
      footer_id: '',
      pages: [
        {
          name: '',
          description: '',
          layout_id: '',
        },
      ],
    };

    const reportData = ref(JSON.parse(JSON.stringify(blankReportData)));
    const resetreportData = () => {
      reportData.value = JSON.parse(JSON.stringify(blankReportData));
    };

    const styles = ref([]);
    let res = store.dispatch('report/fetchStyles')
      .then((response) => {
        const { data } = response.data;
        styles.value = data.map((c) => ({
          label: c.name,
          value: c.id,
          ...c,
        }));
      });
    const headers = ref([]);
    res = store.dispatch('report/fetchHeaders')
      .then((response) => {
        const { data } = response.data;
        headers.value = data.map((c) => ({
          label: c.name, value: c.id, ...c,
        }));
      });
    const footers = ref([]);
    res = store.dispatch('report/fetchFooters')
      .then((response) => {
        const { data } = response.data;
        footers.value = data.map((c) => ({
          label: c.name, value: c.id, ...c,
        }));
      });

    const layouts = ref([]);
    res = store.dispatch('report/fetchLayouts')
      .then((response) => {
        const { data } = response.data;
        layouts.value = data.map((c) => ({
          label: c.name, value: c.id, ...c,
        }));
      });

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetreportData);

    return {
      reportData,
      styles,
      headers,
      footers,
      layouts,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-report-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
